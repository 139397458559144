import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { airportData } from "../assets/arrays/airportData";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const [tripType, setTripType] = useState("One way");
  const [departureCity, setDepartureCity] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [passengers, setPassengers] = useState(1);
  const [filteredDepartureCities, setFilteredDepartureCities] = useState([]);
  const [filteredDestinationCities, setFilteredDestinationCities] = useState(
    []
  );
  const [departureDate, setDepartureDate] = useState(getToday());
  const [returnDate, setReturnDate] = useState(getTomorrow());
  const navigate = useNavigate();

  useEffect(() => {
    setFilteredDepartureCities(filterCities(departureCity));
  }, [departureCity]);

  useEffect(() => {
    setFilteredDestinationCities(filterCities(destinationCity));
  }, [destinationCity]);

  const filterCities = (input) => {
    return airportData.filter(
      (city) =>
        city.city.toLowerCase().includes(input.toLowerCase()) ||
        city.country.toLowerCase().includes(input.toLowerCase())
    );
  };

  const handleSearch = () => {
    if (
      !departureCity ||
      !destinationCity ||
      !departureDate ||
      (tripType === "Round trip" && !returnDate)
    ) {
      toast.error("Please fill in all fields.");
    } else if (departureCity === destinationCity) {
      toast.error("Your location and destination can not be the same.");
    } else {
      const searchData = {
        tripType,
        departureCity,
        destinationCity,
        passengers,
        departureDate,
        returnDate: tripType === "Round trip" ? returnDate : null,
      };

      sessionStorage.setItem("searchData", JSON.stringify(searchData));
      navigate("/search-results");
    }
  };

  function getToday() {
    const today = new Date();
    return today.toISOString().substr(0, 10); // Format as YYYY-MM-DD
  }

  function getTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().substr(0, 10); // Format as YYYY-MM-DD
  }

  return (
    <div className="w-full h-screen bg-homebg bg-center bg-no-repeat bg-cover">
      <div className="w-full h-full flex flex-col gap-5 justify-center items-center backdrop-blur-sm bg-black/30">
        <h1 className="text-white text-xl  lg:text-5xl font-bold">
          Active Service Aviation
        </h1>
        <p className="text-white text-lg">
          Booking flights for active duty members
        </p>
        <div className="w-48 bg-white rounded-3xl p-1 flex">
          <button
            className={`w-full rounded-3xl text-xs h-full py-1 transition-all ease-in-out duration-700 ${
              tripType === "One way" ? "bg-primary" : "text-yellow"
            }`}
            onClick={() => {
              setTripType("One way");
              setReturnDate(getTomorrow());
            }}
          >
            One way
          </button>
          <button
            className={`w-full rounded-3xl text-xs h-full py-1 transition-all ease-in-out duration-700 ${
              tripType === "Round trip" ? "bg-primary" : "text-yellow"
            }`}
            onClick={() => setTripType("Round trip")}
          >
            Round trip
          </button>
        </div>
        <div className="w-5/6 md:w-1/2 lg:w-1/3 p-10 bg-white shadow border border-gray-100 rounded-md gap-2 flex flex-col">
          <div className="w-full flex gap-2">
            <div className="w-full flex flex-col gap-1">
              <label className="text-black text-xss w-full">Where from?</label>
              <input
                type="text"
                className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
                value={departureCity}
                onChange={(e) => {
                  setDepartureCity(e.target.value);
                  setFilteredDepartureCities(filterCities(e.target.value));
                }}
                list="departureOptions"
              />
              <datalist id="departureOptions">
                {filteredDepartureCities.map((city) => (
                  <option
                    key={city.airport}
                    value={`${city.city}, ${city.country}`}
                  />
                ))}
              </datalist>
            </div>
            <div className="w-full flex flex-col gap-1">
              <label className="text-black text-xss w-full">Where to?</label>
              <input
                type="text"
                className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
                value={destinationCity}
                onChange={(e) => {
                  setDestinationCity(e.target.value);
                  setFilteredDestinationCities(filterCities(e.target.value));
                }}
                list="destinationOptions"
              />
              <datalist id="destinationOptions">
                {filteredDestinationCities.map((city) => (
                  <option
                    key={city.airport}
                    value={`${city.city}, ${city.country}`}
                  />
                ))}
              </datalist>
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div className="w-full flex flex-col gap-1">
              <label className="text-black text-xss w-full">
                Departure date
              </label>
              <input
                type="date"
                className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
                value={departureDate}
                onChange={(e) => setDepartureDate(e.target.value)}
              />
            </div>
            {tripType === "Round trip" && (
              <div className="w-full flex flex-col gap-1">
                <label className="text-black text-xss w-full">
                  Return date
                </label>
                <input
                  type="date"
                  className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
                  value={returnDate}
                  onChange={(e) => setReturnDate(e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="w-full flex flex-col gap-1">
            <label className="text-black text-xss w-full">Passengers</label>
            <div className="w-full flex justify-between items-end">
              <div className="flex items-center justify-center gap-5 text-xs">
                <button
                  className="bg-gray-100 w-6 h-6 rounded-full items-center justify-center flex hover:bg-primary hover:text-black text-primary"
                  onClick={() => setPassengers((prev) => Math.max(1, prev - 1))}
                >
                  <i className="fa-solid fa-minus"></i>
                </button>
                <div className="border-2 border-gray-100 rounded w-7 h-7 flex items-center justify-center">
                  {passengers}
                </div>
                <button
                  className="bg-gray-100 w-6 h-6 rounded-full items-center justify-center flex hover:bg-primary hover:text-black text-primary"
                  onClick={() => setPassengers((prev) => prev + 1)}
                >
                  <i className="fa-solid fa-plus"></i>
                </button>
              </div>
              <span className="text-xs text-primary">
                {passengers} Passenger(s)
              </span>
            </div>
          </div>
          <button
            onClick={handleSearch}
            className="w-full py-1.5 text-xs text-black bg-primary shadow rounded transition-all ease-in-out duration-500 hover:bg-gray-100 hover:text-primary"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
