import React from 'react'

const CompleteBooking = ({ data1, data2, seat, selectSeat, sendData }) => {
  return (
    <div className="w-full bg-white rounded-xl shadow p-3 flex flex-col gap-1">
      <h2 className="font-semibold text-primary">Booking and payment</h2>
      <p className="p-2 text-xxs text-gray-500">
        After booking this flight you would need to make a total payment of the
        amount below before 24 hours or the booking would be cancelled.
      </p>
      <div className="w-full text-center text-2xl font-semibold">
        {data2 === null
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(data1.price * data1.passengers)
          : new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format((data1.price + data2.price) * data1.passengers)}
      </div>
      <span className="w-full flex justify-center">
        {seat === null ? (
          <button
            onClick={selectSeat}
            className="py-2 w-32 mt-2 bg-primary text-black text-xs transition-all ease-in-out hover:bg-gray-200 shadow rounded-3xl"
          >
            Confirm Booking
          </button>
        ) : (
          <form onSubmit={sendData}>
            <button className="py-2 w-32 mt-2 bg-primary text-black text-xs transition-all ease-in-out hover:bg-gray-200 shadow rounded-3xl">
              Confirm Booking
            </button>
          </form>
        )}
      </span>
    </div>
  );
};
 
export default CompleteBooking