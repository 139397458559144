import React from "react";

const SelectSeat = ({ seat, setSeat, data1, data2 }) => {
  const getRandomNumber = () => {
    return Math.floor(Math.random() * 30) + 1; // Generates a random number between 1 and 30
  };

  const generateSeatLetter = (type, index) => {
    if (type === "Window seat") {
      return index % 3 === 0 ? "A" : "F"; // Only A and F for window seats
    } else if (type === "Middle seat") {
      return index % 3 === 0 ? "B" : "E"; // Only B and E for middle seats
    } else if (type === "Aisle seat") {
      return index % 3 === 0 ? "C" : "D"; // Only C and D for aisle seats
    }
  };

  const generateSeatNumber = (type, index) => {
    const row = getRandomNumber();
    const seatLetter = generateSeatLetter(type, index);
    return `${row}${seatLetter}`;
  };

  const generateSeatOptions = (type, count, title) => {
    const seatOptions = [];
    for (let i = 0; i < count; i++) {
      seatOptions.push(generateSeatNumber(type, i));
    }
    sessionStorage.setItem(title, seatOptions.join(", "));
    return seatOptions.join(", ");
  };

  return (
    <div className="w-full bg-white rounded-xl shadow p-3 flex flex-col gap-1">
      <h2 className="font-semibold text-primary">Seat Preference</h2>
      <p className="text-gray-500 text-xxs">Choose your preferred seat.</p>
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-2">
        <div
          onClick={() => setSeat("Window seat")}
          className={`w-full border rounded p-5 flex items-center justify-between cursor-pointer ${
            seat === "Window seat" ? "border-primary" : ""
          }`}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1 text-xs text-primary">
              <i className="fa-solid fa-square-full"></i>
              <i className="fa-regular fa-square-full"></i>
              <i className="fa-regular fa-square-full"></i>
            </div>
            <span className="text-xss text-gray-500">Window seat</span>
          </div>
          <span
            className={`py-1 px-4 rounded-3xl text-xss ${
              seat === "Window seat"
                ? "bg-primary text-black"
                : "bg-gray-200 text-gray-500"
            }`}
          >
            {seat === "Window seat" ? "Selected" : "Select"}
          </span>
        </div>
        <div
          onClick={() => setSeat("Middle seat")}
          className={`w-full border rounded p-5 flex items-center justify-between cursor-pointer ${
            seat === "Middle seat" ? "border-primary" : ""
          }`}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1 text-xs text-primary">
              <i className="fa-regular fa-square-full"></i>
              <i className="fa-solid fa-square-full"></i>
              <i className="fa-regular fa-square-full"></i>
            </div>
            <span className="text-xss text-gray-500">Middle seat</span>
          </div>
          <span
            className={`py-1 px-4 rounded-3xl text-xss ${
              seat === "Middle seat"
                ? "bg-primary text-black"
                : "bg-gray-200 text-gray-500"
            }`}
          >
            {seat === "Middle seat" ? "Selected" : "Select"}
          </span>
        </div>
        <div
          onClick={() => setSeat("Aisle seat")}
          className={`w-full border rounded p-5 flex items-center justify-between cursor-pointer ${
            seat === "Aisle seat" ? "border-primary" : ""
          }`}
        >
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-1 text-xs text-primary">
              <i className="fa-regular fa-square-full"></i>
              <i className="fa-regular fa-square-full"></i>
              <i className="fa-solid fa-square-full"></i>
            </div>
            <span className="text-xss text-gray-500">Aisle seat</span>
          </div>
          <span
            className={`py-1 px-4 rounded-3xl text-xss ${
              seat === "Aisle seat"
                ? "bg-primary text-black"
                : "bg-gray-200 text-gray-500"
            }`}
          >
            {seat === "Aisle seat" ? "Selected" : "Select"}
          </span>
        </div>
      </div>
      {seat === null ? null : (
        <span className="text-xs text-gray-500 w-full flex items-center justify-center gap-2">
          {data1 && (
            <span>
              Seat(s): {generateSeatOptions(seat, data1.passengers, "Seat")}
            </span>
          )}
          {data2 ? "|" : null}
          {data2 && (
            <span>
              Return seat(s):{" "}
              {generateSeatOptions(seat, data1.passengers, "Return seat")}
            </span>
          )}
        </span>
      )}
    </div>
  );
};

export default SelectSeat;
