import React from "react";
import Logo from "../../assets/logos/asa.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="w-full p-5 bg-black text-primary flex flex-col gap-2">
      <div className="w-full flex max-lg:flex-col gap-5 border-b border-primary/20 pb-2">
        <div className="lg:w-1/2 w-full flex flex-col gap-1">
          <img src={Logo} alt="" className="w-16" />
          <p className="text-xs text-gray-400">
            Active Service Aviation is a dedicated platform that caters to the
            unique travel needs of active service members, veterans, and their
            families. Our mission is to provide efficient and reliable flight
            services tailored to the military community, ensuring seamless
            travel experiences for every journey.
          </p>
        </div>
        <div className="lg:w-1/2 w-full flex gap-5">
          <div className="w-full flex flex-col gap-1">
            <h5 className="text-lg font-semibold ">Links</h5>
            <Link
              to={"/"}
              className="flex gap-1 items-center text-xs transition-all ease-in-out hover:translate-x-2"
            >
              <i className="fa-solid fa-caret-right"></i>
              <span className="text-white">Book with us</span>
            </Link>
            <Link
              to={"/about-us"}
              className="flex gap-1 items-center text-xs transition-all ease-in-out hover:translate-x-2"
            >
              <i className="fa-solid fa-caret-right"></i>
              <span className="text-white">About us</span>
            </Link>
            <Link
              to={"/terms-and-conditions"}
              className="flex gap-1 items-center text-xs transition-all ease-in-out hover:translate-x-2"
            >
              <i className="fa-solid fa-caret-right"></i>
              <span className="text-white">Terms and condition</span>
            </Link>
            <Link
              to={"/my-bookings"}
              className="flex gap-1 items-center text-xs transition-all ease-in-out hover:translate-x-2"
            >
              <i className="fa-solid fa-caret-right"></i>
              <span className="text-white">My bookings</span>
            </Link>
          </div>
          <div className="w-full flex flex-col gap-1">
            <h5 className="text-lg font-semibold ">Contact Information</h5>
            <div className="flex gap-1 items-center text-xs transition-all ease-in-out hover:translate-x-2">
              <i className="fa-solid fa-envelope"></i>
              <span className="text-white">
                support@activeserviceaviation.com
              </span>
            </div>
            <div className="flex gap-1 items-center text-xs transition-all ease-in-out hover:translate-x-2">
              <i className="fa-solid fa-location-dot"></i>
              <span className="text-white">
                409 Dale Dr Vienna, Georgia(GA), 31092
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full text-center text-xxs">
        Active Service Aviation Ltd - Incorporated © 2024 All rights reserved
      </div>
    </footer>
  );
};

export default Footer;
