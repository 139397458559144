import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FlightDetails from "../components/cards/FlightDetails";
import SelectSeat from "../components/cards/SelectSeat";
import { useSelector } from "react-redux";
import UserInformation from "../components/cards/UserInformation";
import BookingDetails from "../components/details/BookingDetails";
import CompleteBooking from "../components/cards/CompleteBooking";
import { toast } from "react-toastify";
import api from "../assets/apis/api";
import { formatTime } from "../assets/functions/formatTime";
import Footer from "../components/footers/Footer";

const BookingPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [seat, setSeat] = useState(null);
  const token = !!localStorage.getItem("access_token");
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("selectedData"));
    if (data) {
      setData1(data);
    }
    if (data.tripType !== "One way") {
      const secondData = JSON.parse(sessionStorage.getItem("selectedData2"));
      setData2(secondData);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const selectSeat = () => {
    toast.error("Please select a seat");
  };

  const info = {
    passengers: data1?.passengers,
    origin: data1?.origin,
    destination: data1?.destination,
    ticket_type: data1?.ticketType,
    takeoff_time: data1?.takeoffTime,
    landing_time: data1?.landingTime,
    price: data1?.price * data1?.passengers,
    distance: data1?.distance,
    estimated_time: formatTime(data1?.estimatedTime),
    trip_type: data1?.tripType,
    seat_number: sessionStorage.getItem("Seat"),
    user_id: user?.id,
  };

  const info2 = {
    passengers: data1?.passengers,
    origin: data2?.origin,
    destination: data2?.destination,
    ticket_type: data2?.ticketType,
    takeoff_time: data2?.takeoffTime,
    landing_time: data2?.landingTime,
    price: data2?.price * data1?.passengers,
    distance: data2?.distance,
    estimated_time: formatTime(data2?.estimatedTime),
    trip_type: data2?.tripType,
    seat_number: sessionStorage.getItem("Return seat"),
    user_id: user?.id,
  };

  const sendData = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log("data sent:", info);
      const res = await api.post("/book-flight", info);

      if (data1.trip_type === "Round trip") {
        await api.post("/book-return-flight", info2);
      }
      toast.success("Flight booked successfully");
      console.log(res.data.details);
      navigate("/booking-completed");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="w-full h-screen flex justify-center items-center flex-col gap-2">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen bg-homebg bg-center bg-no-repeat bg-cover">
      <div className="w-full h-full flex flex-col gap-5 backdrop-blur-sm bg-black/30 text-white">
        <div className="w-full pt-32 max-md:pt-24 max-md:pb-6 pb-10 flex justify-center items-center">
          <span className="text-xl">Great Choice!</span>
        </div>
        <div className="w-full min-h-[85vh] bg-gray-100 rounded-t-xl p-5 text-black flex flex-col lg:flex-row gap-5">
          <div className="w-full lg:w-3/4 flex flex-col gap-5">
            <FlightDetails data1={data1} />
            {data2 && <FlightDetails data1={data2} />}
            <UserInformation token={token} user={user} navigate={navigate} />
            <SelectSeat
              seat={seat}
              setSeat={setSeat}
              data1={data1}
              data2={data2}
              selectSeat={selectSeat}
            />
            <CompleteBooking
              seat={seat}
              data1={data1}
              data2={data2}
              selectSeat={selectSeat}
              sendData={sendData}
            />
          </div>
          <BookingDetails data1={data1} data2={data2} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BookingPage;
