import React from "react";
import { getDateFromTimestamp } from "../../assets/functions/getDateFromTimestamp";
import { getTimeFromTimestamp } from "../../assets/functions/getTimeFromTimestamp";

const SearchDetails = ({
  setProceed,
  data1,
  data2,
  getAirport,
  searchData,
}) => {
  return (
    <div className="p-5 flex-col w-full lg:w-1/4 h-min bg-white shadow rounded-xl text-gray-500">
      <h2 className="font-semibold text-primary">Details</h2>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">From:</span>
        <span>{getAirport(searchData.departureCity)}</span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">To:</span>
        <span>{getAirport(searchData.destinationCity)}</span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Passengers:</span>
        <span>x{searchData.passengers}</span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Departure Ticket type:</span>
        <span>{data1 !== null ? data1.ticketType : "None"}</span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Departure fare:</span>
        <span>
          {data1 !== null
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(data1.price)
            : new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(0)}
        </span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Departure Date:</span>
        <span>
          {data1 !== null
            ? getDateFromTimestamp(data1.takeoffTime)
            : "Not available"}
        </span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Departure Time:</span>
        <span>
          {data1 !== null
            ? getTimeFromTimestamp(data1.takeoffTime)
            : "Not available"}
        </span>
      </div>
      {searchData.tripType !== "Round trip" ? (
        <div className="flex justify-between py-1.5 w-full text-xs">
          <span className="text-primary">Total fare:</span>
          <span>
            {data1
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(data1.price * searchData.passengers)
              : new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(0)}
          </span>
        </div>
      ) : null}
      {searchData.tripType === "Round trip" ? (
        <>
          <div className="flex justify-between py-1.5 w-full text-xs">
            <span className="text-primary">Return Ticket type:</span>
            <span>{data2 !== null ? data2.ticketType : "None"}</span>
          </div>
          <div className="flex justify-between py-1.5 w-full text-xs">
            <span className="text-primary">Return fare:</span>
            <span>
              {data2 !== null
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(data2.price)
                : new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(0)}
            </span>
          </div>
          <div className="flex justify-between py-1.5 w-full text-xs">
            <span className="text-primary">Return Date:</span>
            <span>
              {data2 !== null
                ? getDateFromTimestamp(data2.takeoffTime)
                : "Not available"}
            </span>
          </div>
          <div className="flex justify-between py-1.5 w-full text-xs">
            <span className="text-primary">Return Time:</span>
            <span>
              {data2 !== null
                ? getTimeFromTimestamp(data2.takeoffTime)
                : "Not available"}
            </span>
          </div>
          <div className="flex justify-between py-1.5 w-full text-xs">
            <span className="text-primary">Total fare:</span>
            <span>
              {data1 && data2
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format((data1.price + data2.price) * searchData.passengers)
                : new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(0)}
            </span>
          </div>
        </>
      ) : null}
      {setProceed()}
    </div>
  );
};

export default SearchDetails;
