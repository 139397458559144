import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../assets/apis/api";
import { getAirport } from "../assets/functions/getAirport";
import Footer from "../components/footers/Footer";

const MyBookingsPage = () => {
  const user = useSelector((state) => state.user);
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getFlights = async () => {
      try {
        const response = await api.get("/user-flight");
        setFlights(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getFlights();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen flex justify-center items-center flex-col gap-2">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div className="w-full h-screen bg-homebg bg-center bg-no-repeat bg-cover">
      <div className="w-full h-full flex flex-col gap-5 backdrop-blur-sm bg-black/30 text-white">
        <div className="w-full pt-32 max-md:pt-24 max-md:pb-6 pb-10 flex justify-center items-center">
          <span className="text-xl capitalize">Hello {user?.firstname}</span>
        </div>
        <div className="w-full h-full bg-gray-100 rounded-t-xl p-5 text-black flex flex-col gap-5 overflow-x-auto">
          <h2 className="font-semibold text-primary">My bookings</h2>

          {flights.length === 0 && (
            <span className="w-full text-center text-gray-500 text-xs">
              You do not have any bookings.
            </span>
          )}
          {flights
            .slice()
            .reverse()
            .map((item) => {
              return (
                <div className="w-full rounded-xl p-3 bg-white shadow flex flex-col gap-1">
                  <h2 className="font-semibold text-primary">
                    Booking Reference: {item.booking_number}
                  </h2>
                  <div className="w-full flex gap-2 items-center justify-between text-xss text-gray-500">
                    <div className="w-5/12 flex flex-col items-start gap-1">
                      <span>{item.takeoff_time}</span>
                      <span>{item.origin}</span>
                      <span className="text-primary">
                        {getAirport(item.origin)}
                      </span>
                    </div>
                    <i className="fa-solid fa-plane text-xs text-primary"></i>
                    <div className="w-5/12 flex flex-col items-end gap-1">
                      <span>{item.landing_time}</span>
                      <span>{item.destination}</span>
                      <span className="text-primary text-right">
                        {getAirport(item.destination)}
                      </span>
                    </div>
                  </div>
                  <div className="w-full flex justify-center gap-2 text-xss text-gray-500">
                    <span>{parseInt(item.distance)}km</span>
                    <span>|</span>
                    <span>{item.estimated_time}</span>
                    <span>|</span>
                    <span>{item.seat_number}</span>
                    <span>|</span>
                    <span>{item.ticket_type}</span>
                    <span>|</span>
                    <span>x{item.passengers}</span>
                  </div>
                  <div className="w-full flex justify-center gap-2 text-xss text-gray-500 mt-2">
                    <span>Price:</span>
                    <span className="capitalize">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(parseFloat(item.price))}
                    </span>
                    <span>|</span>
                    <span>Status:</span>
                    <span className="capitalize">{item.status}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyBookingsPage;
