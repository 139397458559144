import React from 'react'

const UserInformation = ({user, navigate, token}) => {
  return (
    <div className="w-full bg-white rounded-xl shadow p-3 flex flex-col gap-1">
      <h2 className="font-semibold text-primary">Passenger information</h2>
      {token ? (
        <>
          <div className="w-full p-1 text-xxs text-gray-400">Title</div>
          <div className="w-full outline-none border border-gray-100 rounded  text-xs p-1 text-primary">
            {user?.title}
          </div>
          <div className="w-full p-1 text-xxs text-gray-400">First name</div>
          <div className="w-full outline-none border border-gray-100 rounded  text-xs p-1 text-primary capitalize">
            {user?.firstname}
          </div>
          <div className="w-full p-1 text-xxs text-gray-400">Middle name</div>
          <div className="w-full outline-none border border-gray-100 rounded  text-xs p-1 text-primary capitalize">
            {user?.middlename}
          </div>
          <div className="w-full p-1 text-xxs text-gray-400">Last name</div>
          <div className="w-full outline-none border border-gray-100 rounded  text-xs p-1 text-primary capitalize">
            {user?.lastname}
          </div>
          <div className="w-full p-1 text-xxs text-gray-400">Age</div>
          <div className="w-full outline-none border border-gray-100 rounded  text-xs p-1 text-primary">
            Classified
          </div>
          <div className="w-full p-1 text-xxs text-gray-400">Email</div>
          <div className="w-full outline-none border border-gray-100 rounded  text-xs p-1 text-primary">
            {user?.email}
          </div>
        </>
      ) : (
        <>
          <p className="text-gray-500 text-xxs">
            In order to proceed you have to log into your account. If you do not
            have an account or have any issues with your account please visit
            Unit IT Support or Base IT Support.
          </p>
          <button
            onClick={() => {
              navigate("/login");
            }}
            className="py-1 w-full mt-2 bg-primary text-black text-xss transition-all ease-in-out hover:bg-gray-200 shadow"
          >
            Login
          </button>
        </>
      )}
    </div>
  );
}

export default UserInformation