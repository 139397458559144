import React from "react";

const BookingDetails = ({ data1, data2 }) => {
  return (
    <div className="p-5 flex-col w-full lg:w-1/4 h-min bg-white shadow rounded-xl text-gray-500">
      <h2 className="font-semibold text-primary">Payment details</h2>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Fare (Tax inc):</span>
        <span>
          {data2 === null
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(data1.price)
            : new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(data1.price + data2.price)}
        </span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Cancelation protection:</span>
        <span>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(0)}
        </span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Lost baggage protection:</span>
        <span>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(0)}
        </span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Passenger(s):</span>
        <span>x{data1.passengers}</span>
      </div>
      <div className="flex justify-between py-1.5 w-full text-xs">
        <span className="text-primary">Total Fare:</span>
        <span>
          {data2 === null
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(data1.price)
            : new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format((data1.price + data2.price) * data1.passengers)}
        </span>
      </div>
      <div className="bg-primary/10 p-2 text-xxs">
        Note flight price may increase if you book later.
      </div>
    </div>
  );
};

export default BookingDetails;
