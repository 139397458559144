import React, { useState } from "react";
import api from "../assets/apis/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SignupPage = () => {
  const navigate = useNavigate();
  const [hidden, sethidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_Confirmation] = useState("");
  const user = {
    title,
    firstname,
    middlename,
    lastname,
    email,
    password,
    password_confirmation,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post("/signup", user);
      toast.success(response.data.message);
      navigate("/login");
    } catch (error) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).forEach((key) => {
          toast.error(error.response.data.errors[key][0]);
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="w-full h-screen bg-homebg bg-center bg-no-repeat bg-cover">
      <div className="w-full h-full flex flex-col gap-5 justify-center items-center backdrop-blur-sm bg-black/30">
        <form
          onSubmit={handleSubmit}
          className="w-5/6 md:w-1/2 lg:w-1/3 p-10 bg-white shadow border border-gray-100 rounded-md gap-2 flex flex-col"
        >
          <h1 className="w-full text-center text-lg text-primary">
            Create account
          </h1>
          <select
            type="text"
            className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary appearance-none"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          >
            <option value="">Select title</option>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
          </select>
          <div className="w-full flex gap-2">
            <input
              type="text"
              placeholder="First name"
              className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <input
              type="text"
              placeholder="Middle name"
              className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
              value={middlename}
              onChange={(e) => setMiddlename(e.target.value)}
            />
            <input
              type="text"
              placeholder="Last name"
              className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
          <input
            type="email"
            placeholder="Email"
            className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="w-full flex gap-2">
            <input
              type={hidden ? "password" : "text"}
              placeholder="Password"
              className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type={hidden ? "password" : "text"}
              placeholder="Confirm password"
              className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
              value={password_confirmation}
              onChange={(e) => setPassword_Confirmation(e.target.value)}
            />
          </div>
          <div className="w-full flex justify-end">
            {hidden ? (
              <div
                onClick={() => sethidden(!hidden)}
                className="flex items-center gap-1 text-primary text-xs cursor-pointer"
              >
                <p className="">Show password</p>
                <i class="fa-regular fa-eye"></i>
              </div>
            ) : (
              <div
                onClick={() => sethidden(!hidden)}
                className="flex items-center gap-1 text-primary text-xs cursor-pointer"
              >
                <p className="">Hide password</p>
                <i class="fa-regular fa-eye-slash"></i>
              </div>
            )}
          </div>
          {loading ? (
            <div className="w-full flex justify-center">
              <div className="loader"></div>
            </div>
          ) : (
            <button className="w-full py-1.5 text-xs text-black bg-primary shadow rounded transition-all ease-in-out duration-500 hover:bg-gray-100 hover:text-primary">
              Signup
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
