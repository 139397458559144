import React from "react";
import Footer from "../components/footers/Footer";

const AboutPage = () => {
  return (
    <div className="w-full min-h-screen bg-homebg bg-center bg-no-repeat bg-cover">
      <div className="w-full h-full flex flex-col gap-5 backdrop-blur-sm bg-black/30 text-white">
        <div className="w-full pt-32 max-md:pt-24 max-md:pb-6 pb-10 flex justify-center items-center">
          <h1 className="text-2xl font-semibold capitalize">About Us</h1>
        </div>
        <div className="w-full h-full bg-gray-100 rounded-t-xl p-10 text-gray-500 flex flex-col gap-2 text-sm">
          <p>
            Welcome to Active Service Aviation, your premier destination for
            booking military flights with ease and convenience. At Active
            Service Aviation, we specialize in providing efficient and reliable
            flight services tailored to meet the unique needs of active service
            members, veterans, and their families.
          </p>
          <h3 className="text-xl text-primary font-semibold mt-3">
            Our Mission
          </h3>
          <p>
            Our mission at Active Service Aviation is to bridge the gap between
            military service and civilian life by offering seamless travel
            solutions for military personnel and their loved ones. We are
            dedicated to providing safe, secure, and cost-effective flight
            options that prioritize your comfort and convenience.
          </p>
          <h3 className="text-xl text-primary font-semibold mt-3">
            What We Offer
          </h3>
          <ul className="leading-7">
            <li>
              <span className="text-primary font-semibold">
                Military Flight Booking:
              </span>{" "}
              We offer a comprehensive booking platform specifically designed
              for military flights, ensuring hassle-free reservations for all
              your travel needs.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Dedicated Support:
              </span>{" "}
              Our experienced team of aviation experts is available around the
              clock to assist you with booking inquiries, flight details, and
              any other assistance you may require.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Exclusive Deals:
              </span>{" "}
              Take advantage of exclusive deals and discounts available only to
              our valued military customers, making your travel experience even
              more affordable.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Flexible Options:
              </span>{" "}
              Whether you're on official duty, going on a personal trip, or
              reuniting with family, our flexible booking options cater to a
              wide range of travel requirements.
            </li>
          </ul>
          <h3 className="text-xl text-primary font-semibold mt-3">
            Why Choose Us
          </h3>
          <ul className="leading-7">
            <li>
              <span className="text-primary font-semibold">
                Military Focus:
              </span>{" "}
              With a deep understanding of military protocols and requirements,
              we ensure that your travel experience is tailored to meet the
              standards expected by our esteemed service members.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Security and Reliability:
              </span>{" "}
              Your safety and security are our top priorities. We partner with
              reputable airlines and adhere to stringent safety measures to
              guarantee a smooth and secure journey.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Easy Booking Process:
              </span>{" "}
              Our user-friendly booking platform allows you to search, select,
              and book your flights with ease, saving you time and effort.
            </li>
          </ul>
          <h3 className="text-xl text-primary font-semibold mt-3">
            Contact Us
          </h3>
          <p>
            Have questions or need assistance? Don't hesitate to reach out to
            our friendly team. You can contact us via email us at
            support@activeserviceaviation.com. We're here to serve you and
            ensure your travel experience with Active Service Aviation is
            nothing short of exceptional.
          </p>
          <p>
            Thank you for choosing Active Service Aviation. We look forward to
            serving you on your next flight!
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
