export const airportData = [
  {
    city: "Los Angeles",
    airport: "Los Angeles International Airport (LAX)",
    country: "USA",
    coordinates: { latitude: 34.0522, longitude: -118.2437 },
  },
  {
    city: "New York City",
    airport: "John F. Kennedy International Airport (JFK)",
    country: "USA",
    coordinates: { latitude: 40.7128, longitude: -74.006 },
  },
  {
    city: "Chicago",
    airport: "O'Hare International Airport (ORD)",
    country: "USA",
    coordinates: { latitude: 41.8781, longitude: -87.6298 },
  },
  {
    city: "San Francisco",
    airport: "San Francisco International Airport (SFO)",
    country: "USA",
    coordinates: { latitude: 37.7749, longitude: -122.4194 },
  },
  {
    city: "Miami",
    airport: "Miami International Airport (MIA)",
    country: "USA",
    coordinates: { latitude: 25.7617, longitude: -80.1918 },
  },
  {
    city: "Dallas",
    airport: "Dallas/Fort Worth International Airport (DFW)",
    country: "USA",
    coordinates: { latitude: 32.8998, longitude: -97.0403 },
  },
  {
    city: "Houston",
    airport: "George Bush Intercontinental Airport (IAH)",
    country: "USA",
    coordinates: { latitude: 29.9902, longitude: -95.3368 },
  },
  {
    city: "Las Vegas",
    airport: "Harry Reid International Airport (LAS)",
    country: "USA",
    coordinates: { latitude: 36.084, longitude: -115.1537 },
  },
  {
    city: "Reno",
    airport: "Reno-Tahoe International Airport (RNO)",
    country: "USA",
    coordinates: { latitude: 39.4997, longitude: -119.7681 },
  },
  {
    city: "Kabul",
    airport: "Hamid Karzai International Airport (KBL)",
    country: "Afghanistan",
    coordinates: { latitude: 34.5553, longitude: 69.2075 },
  },
  {
    city: "Kandahar",
    airport: "Kandahar International Airport (KDH)",
    country: "Afghanistan",
    coordinates: { latitude: 31.6079, longitude: 65.7053 },
  },
  {
    city: "Herat",
    airport: "Herat International Airport (HEA)",
    country: "Afghanistan",
    coordinates: { latitude: 34.209, longitude: 62.2283 },
  },
  {
    city: "Mazar-i-Sharif",
    airport: "Mazar-i-Sharif International Airport (MZR)",
    country: "Afghanistan",
    coordinates: { latitude: 36.7069, longitude: 67.1122 },
  },
  {
    city: "Henderson",
    airport: "Henderson Executive Airport (HND)",
    country: "USA",
    coordinates: { latitude: 35.9729, longitude: -115.1341 },
  },
  {
    city: "Newark",
    airport: "Newark Liberty International Airport (EWR)",
    country: "USA",
    coordinates: { latitude: 40.6895, longitude: -74.1745 },
  },
  {
    city: "Atlantic City",
    airport: "Atlantic City International Airport (ACY)",
    country: "USA",
    coordinates: { latitude: 39.4576, longitude: -74.5772 },
  },
  {
    city: "Sydney",
    airport: "Sydney Airport (SYD)",
    country: "Australia",
    coordinates: { latitude: -33.8688, longitude: 151.2093 },
  },
  {
    city: "Melbourne",
    airport: "Melbourne Airport (MEL)",
    country: "Australia",
    coordinates: { latitude: -37.8136, longitude: 144.9631 },
  },
  {
    city: "Brisbane",
    airport: "Brisbane Airport (BNE)",
    country: "Australia",
    coordinates: { latitude: -27.4698, longitude: 153.0251 },
  },
  {
    city: "Perth",
    airport: "Perth Airport (PER)",
    country: "Australia",
    coordinates: { latitude: -31.9505, longitude: 115.8605 },
  },
  {
    city: "Adelaide",
    airport: "Adelaide Airport (ADL)",
    country: "Australia",
    coordinates: { latitude: -34.9285, longitude: 138.6007 },
  },
  {
    city: "London",
    airport: "Heathrow Airport (LHR)",
    country: "United Kingdom",
    coordinates: { latitude: 51.5074, longitude: -0.1278 },
  },
  {
    city: "Manchester",
    airport: "Manchester Airport (MAN)",
    country: "United Kingdom",
    coordinates: { latitude: 53.4808, longitude: -2.2426 },
  },
  {
    city: "Birmingham",
    airport: "Birmingham Airport (BHX)",
    country: "United Kingdom",
    coordinates: { latitude: 52.4862, longitude: -1.8904 },
  },
  {
    city: "Glasgow",
    airport: "Glasgow Airport (GLA)",
    country: "United Kingdom",
    coordinates: { latitude: 55.8642, longitude: -4.2518 },
  },
  {
    city: "Edinburgh",
    airport: "Edinburgh Airport (EDI)",
    country: "United Kingdom",
    coordinates: { latitude: 55.9533, longitude: -3.1883 },
  },
  {
    city: "Paris",
    airport: "Charles de Gaulle Airport (CDG)",
    country: "France",
    coordinates: { latitude: 49.0097, longitude: 2.5479 },
  },
  {
    city: "Nice",
    airport: "Nice Côte d'Azur Airport (NCE)",
    country: "France",
    coordinates: { latitude: 43.7102, longitude: 7.2619 },
  },
  {
    city: "Lyon",
    airport: "Lyon–Saint-Exupéry Airport (LYS)",
    country: "France",
    coordinates: { latitude: 45.764, longitude: 4.8357 },
  },
  {
    city: "Marseille",
    airport: "Marseille Provence Airport (MRS)",
    country: "France",
    coordinates: { latitude: 43.2965, longitude: 5.3698 },
  },
  {
    city: "Toulouse",
    airport: "Toulouse–Blagnac Airport (TLS)",
    country: "France",
    coordinates: { latitude: 43.6047, longitude: 1.4442 },
  },
  {
    city: "Madrid",
    airport: "Adolfo Suárez Madrid–Barajas Airport (MAD)",
    country: "Spain",
    coordinates: { latitude: 40.4168, longitude: -3.7038 },
  },
  {
    city: "Barcelona",
    airport: "Barcelona–El Prat Airport (BCN)",
    country: "Spain",
    coordinates: { latitude: 41.3851, longitude: 2.1734 },
  },
  {
    city: "Valencia",
    airport: "Valencia Airport (VLC)",
    country: "Spain",
    coordinates: { latitude: 39.4699, longitude: -0.3763 },
  },
  {
    city: "Seville",
    airport: "Seville Airport (SVQ)",
    country: "Spain",
    coordinates: { latitude: 37.3886, longitude: -5.9823 },
  },
  {
    city: "Bilbao",
    airport: "Bilbao Airport (BIO)",
    country: "Spain",
    coordinates: { latitude: 43.263, longitude: -2.934 },
  },
  {
    city: "Sana'a",
    airport: "Sana'a International Airport (SAH)",
    country: "Yemen",
    coordinates: { latitude: 15.3694, longitude: 44.191 },
  },
  {
    city: "Aden",
    airport: "Aden International Airport (ADE)",
    country: "Yemen",
    coordinates: { latitude: 12.8274, longitude: 45.0281 },
  },
  {
    city: "Damascus",
    airport: "Damascus International Airport (DAM)",
    country: "Syria",
    coordinates: { latitude: 33.5138, longitude: 36.2765 },
  },
  {
    city: "Aleppo",
    airport: "Aleppo International Airport (ALP)",
    country: "Syria",
    coordinates: { latitude: 36.18, longitude: 37.2247 },
  },
  {
    city: "Istanbul",
    airport: "Istanbul Airport (IST)",
    country: "Turkey",
    coordinates: { latitude: 41.0082, longitude: 28.9784 },
  },
  {
    city: "Ankara",
    airport: "Esenboğa International Airport (ESB)",
    country: "Turkey",
    coordinates: { latitude: 39.9334, longitude: 32.8597 },
  },
  {
    city: "Izmir",
    airport: "İzmir Adnan Menderes Airport (ADB)",
    country: "Turkey",
    coordinates: { latitude: 38.4192, longitude: 27.1287 },
  },
  {
    city: "Kuwait City",
    airport: "Kuwait International Airport (KWI)",
    country: "Kuwait",
    coordinates: { latitude: 29.3759, longitude: 47.9774 },
  },
  {
    city: "Jeddah",
    airport: "King Abdulaziz International Airport (JED)",
    country: "Saudi Arabia",
    coordinates: { latitude: 21.4858, longitude: 39.1925 },
  },
  {
    city: "Riyadh",
    airport: "King Khalid International Airport (RUH)",
    country: "Saudi Arabia",
    coordinates: { latitude: 24.7136, longitude: 46.6753 },
  },
  {
    city: "Dubai",
    airport: "Dubai International Airport (DXB)",
    country: "United Arab Emirates",
    coordinates: { latitude: 25.2532, longitude: 55.3657 },
  },
  {
    city: "Abu Dhabi",
    airport: "Abu Dhabi International Airport (AUH)",
    country: "United Arab Emirates",
    coordinates: { latitude: 24.4539, longitude: 54.3773 },
  },
  {
    city: "Tbilisi",
    airport: "Tbilisi International Airport (TBS)",
    country: "Georgia",
    coordinates: { latitude: 41.7151, longitude: 44.8271 },
  },
];
