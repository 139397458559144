import React from 'react'
import { getAirport } from '../../assets/functions/getAirport';
import { getDateFromTimestamp } from '../../assets/functions/getDateFromTimestamp';
import { getTimeFromTimestamp } from '../../assets/functions/getTimeFromTimestamp';

const FlightDetails = ({data1}) => {
  return (
    <div className="w-full rounded-xl p-3 bg-white shadow flex flex-col gap-1">
      <h2 className="font-semibold text-primary">Flight details</h2>
      <div className="w-full flex gap-2 items-center justify-between text-xss text-gray-500">
        <div className="w-5/12 flex flex-col items-start gap-1">
          <span>
            {getDateFromTimestamp(data1?.takeoffTime)} at{" "}
            {getTimeFromTimestamp(data1?.takeoffTime)}
          </span>
          <span>{data1?.origin}</span>
          <span className="text-primary">{getAirport(data1?.origin)}</span>
        </div>
        <i className="fa-solid fa-plane text-xs text-primary"></i>
        <div className="w-5/12 flex flex-col items-end gap-1">
          <span>
            {getDateFromTimestamp(data1?.landingTime)} at{" "}
            {getTimeFromTimestamp(data1?.landingTime)}
          </span>
          <span>{data1?.destination}</span>
          <span className="text-primary text-right">
            {getAirport(data1?.destination)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default FlightDetails