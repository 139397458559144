import React from "react";
import Footer from "../components/footers/Footer";

const TermsAndConditionPage = () => {
  return (
    <div className="w-full min-h-screen bg-homebg bg-center bg-no-repeat bg-cover">
      <div className="w-full h-full flex flex-col gap-5 backdrop-blur-sm bg-black/30 text-white">
        <div className="w-full pt-32 max-md:pt-24 max-md:pb-6 pb-10 flex justify-center items-center">
          <h1 className="text-2xl font-semibold capitalize">
            Terms and Conditions
          </h1>
        </div>
        <div className="w-full h-full bg-gray-100 rounded-t-xl p-10 text-gray-500 flex flex-col gap-2 text-sm">
          <p>
            Welcome to Active Service Aviation. These terms and conditions
            outline the rules and regulations for using our website.
          </p>
          <p>
            By accessing this website, we assume you accept these terms and
            conditions. Do not continue to use Active Service Aviation if you do
            not agree to all of the terms and conditions stated on this page.
          </p>
          <h3 className="text-xl text-primary font-semibold mt-3">
            Booking and Reservations
          </h3>
          <ul className="leading-7">
            <li>
              <span className="text-primary font-semibold">Eligibility:</span>{" "}
              Bookings are open to active service members, veterans, and their
              families. By booking with Active Service Aviation, you confirm
              that you meet the eligibility criteria.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Flight Reservations:
              </span>{" "}
              All flight reservations are subject to availability. We do not
              guarantee availability for specific flights and routes, especially
              during peak travel periods.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Booking Confirmation:
              </span>{" "}
              Your booking is confirmed only after receiving a booking
              confirmation email or notification from Active Service Aviation.
              Until then, your booking is considered pending.
            </li>
          </ul>
          <h3 className="text-xl text-primary font-semibold mt-3">
            Payment and Fees
          </h3>
          <ul className="leading-7">
            <li>
              <span className="text-primary font-semibold">Payment:</span>{" "}
              Payment for flight bookings must be made in full at the time of
              booking. We accept major credit cards and other payment methods as
              specified during the booking process.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Fees and Charges:
              </span>{" "}
              Additional fees, such as service charges, taxes, and baggage fees,
              may apply to your booking. These fees are clearly outlined during
              the booking process and must be paid along with the base fare.
            </li>
          </ul>
          <h3 className="text-xl text-primary font-semibold mt-3">
            Cancellations and Refunds
          </h3>
          <ul className="leading-7">
            <li>
              <span className="text-primary font-semibold">
                Cancellation Policy:
              </span>{" "}
              Cancellation of bookings may be subject to fees and penalties as
              per the fare rules and airline policies. Refunds, if applicable,
              will be processed based on the fare type and cancellation
              timeline.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Refund Process:
              </span>{" "}
              Refunds, if approved, will be processed to the original payment
              method used during booking. Processing times may vary depending on
              the payment provider and banking institutions.
            </li>
          </ul>
          <h3 className="text-xl text-primary font-semibold mt-3">
            Travel Documentation
          </h3>
          <ul className="leading-7">
            <li>
              <span className="text-primary font-semibold">
                Identification:
              </span>{" "}
              All passengers must carry valid government-issued identification
              and travel documents as required by airlines and authorities.
              Failure to provide proper identification may result in denied
              boarding.
            </li>
            <li>
              <span className="text-primary font-semibold">
                Visa and Entry Requirements:
              </span>{" "}
              It is the passenger's responsibility to ensure compliance with
              visa requirements, entry permits, and travel regulations for their
              destination. Active Service Aviation is not liable for denied
              entry due to improper documentation.
            </li>
          </ul>
          <h3 className="text-xl text-primary font-semibold mt-3">
            Contact Us
          </h3>
          <p>
            Have questions or need assistance? Don't hesitate to reach out to
            our friendly team. You can contact us via email us at
            support@activeserviceaviation.com. We're here to serve you and
            ensure your travel experience with Active Service Aviation is
            nothing short of exceptional.
          </p>
          <p>
            Thank you for choosing Active Service Aviation. We look forward to
            serving you on your next flight!
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditionPage;
