import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logos/asa3.png";
import Logo2 from "../../assets/logos/asa.png";
import api from "../../assets/apis/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);
  const token = !!localStorage.getItem("access_token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const logoutUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post("/logout");
      toast.success(response.data.message);
      localStorage.removeItem("access_token");
      dispatch({ type: "LOGOUT" });
      navigate("/");
      setTimeout(() => {
        setIsOpen(false);
      }, 1000);
    } catch (error) {
      if (error.response.data.message === "Unauthenticated.") {
        localStorage.removeItem("access_token");
        dispatch({ type: "LOGOUT" });
        navigate("/login");
        setTimeout(() => {
          setIsOpen(false);
        }, 1000);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav ref={navbarRef} className="w-full h-16 fixed z-20">
      <div className="w-full h-full px-5 flex justify-between items-center">
        <Link to="/">
          <img src={Logo} alt="Logo" className="h-12" />
        </Link>
        <div
          onClick={toggleMenu}
          className="flex items-center gap-1 py-1 px-3 border-primary border-2 rounded-3xl cursor-pointer backdrop-blur"
        >
          <div className="w-3 h-3 flex flex-col justify-between transition-all duration-300 transform">
            <span
              className={`w-full h-0.5 bg-primary transition-all ${
                isOpen ? "rotate-45 translate-y-1.5" : ""
              }`}
            ></span>
            <span
              className={`w-2.5 h-0.5 bg-primary transition-all ${
                isOpen ? "opacity-0" : ""
              }`}
            ></span>
            <span
              className={`w-full h-0.5 bg-primary transition-all ${
                isOpen ? "-rotate-45 -translate-y-1" : ""
              }`}
            ></span>
          </div>
          <span className="text-primary">Menu</span>
        </div>
      </div>
      <div
        className={`absolute top-16 right-0 w-80 max-md:w-64 bg-white shadow-md transform transition-transform px-5 py-8 rounded-lg ${
          isOpen ? "-translate-x-16" : "translate-x-full"
        } text-sm font-light flex flex-col`}
      >
        <div className="w-full py-3 px-3 flex justify-between items-center">
          <img src={Logo2} alt="Logo" className="h-10" />
        </div>
        <Link
          to="/"
          onClick={() => setIsOpen(false)}
          className="w-full py-3 px-3 border-b hover:bg-gray-100 hover:text-primary transition-all ease-in-out"
        >
          Book with us
        </Link>
        {token ? (
          <Link
            to="/my-bookings"
            onClick={() => setIsOpen(false)}
            className="w-full py-3 px-3 border-b hover:bg-gray-100 hover:text-primary transition-all ease-in-out"
          >
            My bookings
          </Link>
        ) : (
          <Link
            to="/login"
            onClick={() => setIsOpen(false)}
            className="w-full py-3 px-3 border-b hover:bg-gray-100 hover:text-primary transition-all ease-in-out"
          >
            Log in
          </Link>
        )}
        <Link
          to="/about-us"
          onClick={() => setIsOpen(false)}
          className="w-full py-3 px-3 border-b hover:bg-gray-100 hover:text-primary transition-all ease-in-out"
        >
          About us
        </Link>
        <Link
          to="/terms-and-conditions"
          onClick={() => setIsOpen(false)}
          className="w-full py-3 px-3 hover:bg-gray-100 hover:text-primary transition-all ease-in-out"
        >
          Terms and condition
        </Link>
        {token && (
          <button
            onClick={logoutUser}
            className="w-full py-3 px-3 hover:text-red-300 text-red-500 transition-all ease-in-out"
          >
            {loading ? "Logging out..." : "Logout"}
          </button>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
