import React from "react";
import { Link } from "react-router-dom";

const SuccessPage = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center gap-5 p-10">
      <h2 className="font-semibold text-primary text-xl">Booking completed</h2>
      <i className="fa-solid fa-square-check text-5xl text-green-500"></i>
      <p className="text-gray-500 text-xss text-center">
        Your booking has been saved and would be available for the next 24hours.
        Please vist the appropriate office to make your payment.
      </p>
      <Link
        to="/"
        className="flex justify-center py-1 w-40 mt-2 bg-primary text-black text-xss transition-all ease-in-out hover:bg-gray-200 shadow"
      >
        Done
      </Link>
    </div>
  );
};

export default SuccessPage;
