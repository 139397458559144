import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import api from "../apis/api";

const useUserDataFetching = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");

  const fetchUserData = async () => {
    try {
      const res = await api.post("/me");
      dispatch({ type: "SET_USER", payload: res.data });
    } catch (error) {
      localStorage.removeItem("access_token");
      dispatch({ type: "LOGOUT" });
      navigate("/");
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { fetchUserData };
};

export default useUserDataFetching;
