import React from "react";
import { formatTime } from "../../assets/functions/formatTime";
import { getDateFromTimestamp } from "../../assets/functions/getDateFromTimestamp";
import { getTimeFromTimestamp } from "../../assets/functions/getTimeFromTimestamp";

const Flightcard2 = ({
  ticketPrices,
  takeoffDateTime,
  searchData,
  landingDateTime,
  estimatedTime,
  distance,
  saveSelectedData,
}) => {
  return (
    <div className="w-full flex flex-col gap-2 bg-white shadow rounded-xl">
      <div className="w-full flex gap-2 items-center justify-between p-3 max-md:p-5">
        <div className="w-1/3 border-gray-300 flex flex-col items-start">
          <span className="text-xs max-md:text-sm">
            {getTimeFromTimestamp(takeoffDateTime)}
          </span>
          <span className="text-xss max-md:text-xs">
            {getDateFromTimestamp(takeoffDateTime)}
          </span>
          <span className="text-xss max-md:text-xs text-primary">
            {searchData.destinationCity}
          </span>
        </div>
        <div className="flex gap-4 items-center text-xs text-primary max-lg:hidden">
          <i class="fa-solid fa-plane-departure"></i>
          <div className="w-20 bg-black h-[1px]" />
          <i class="fa-solid fa-plane-arrival"></i>
        </div>
        <i className="fa-solid fa-plane text-xs text-primary lg:hidden"></i>
        <div className="w-1/3 border-gray-300 flex flex-col items-end">
          <span className="text-xs max-md:text-sm">
            {getTimeFromTimestamp(landingDateTime)}
          </span>
          <span className="text-xss max-md:text-xs">
            {getDateFromTimestamp(landingDateTime)}
          </span>
          <span className="text-xss max-md:text-xs text-primary">
            {searchData.departureCity}
          </span>
        </div>
      </div>
      <div className="w-full text-center text-xxs max-md:text-xss">
        {parseInt(estimatedTime) > 20
          ? "2 stops"
          : parseInt(estimatedTime) > 10
          ? "1 stop"
          : "Nonstop"}{" "}
        | {distance.toFixed(2)} km | {formatTime(estimatedTime)}.
      </div>
      <div className="w-full grid grid-cols-3">
        <button
          onClick={() => {
            saveSelectedData(
              ticketPrices.economy,
              "Economy",
              takeoffDateTime,
              landingDateTime
            );
          }}
          className="w-full flex flex-col"
        >
          <div className="w-full py-0.5 text-center bg-primary text-black rounded-t-lg text-xxs font-medium">
            Economy
          </div>
          <div className="w-full p-1 border-x flex flex-col items-center justify-center text-xss max-md:text-xs gap-1 text-gray-600 hover:bg-gray-100 transition-all ease-in-out duration-700">
            <span>From</span>
            <span>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(ticketPrices.economy)}
            </span>
          </div>
        </button>
        <button
          onClick={() => {
            saveSelectedData(
              ticketPrices.businessClass,
              "Business Class",
              takeoffDateTime,
              landingDateTime
            );
          }}
          className="w-full flex flex-col"
        >
          <div className="w-full py-0.5 text-center bg-gray-200 text-black rounded-t-lg text-xxs font-medium">
            Business Class
          </div>
          <div className="w-full p-1 border-x flex flex-col items-center justify-center text-xss max-md:text-xs gap-1 text-gray-600 hover:bg-gray-100 transition-all ease-in-out duration-700">
            <span>From</span>
            <span>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(ticketPrices.businessClass)}
            </span>
          </div>
        </button>
        <button
          onClick={() => {
            saveSelectedData(
              ticketPrices.firstClass,
              "First Class",
              takeoffDateTime,
              landingDateTime
            );
          }}
          className="w-full flex flex-col"
        >
          <div className="w-full py-0.5 text-center bg-gray-200 text-black rounded-t-lg text-xxs font-medium">
            First Class
          </div>
          <div className="w-full p-1 border-x flex flex-col items-center justify-center text-xss max-md:text-xs gap-1 text-gray-600 hover:bg-gray-100 transition-all ease-in-out duration-700">
            <span>From</span>
            <span>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(ticketPrices.firstClass)}
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default Flightcard2;
