import { airportData } from "../arrays/airportData";

export const getAirport = (cityName) => {
  const [city, country] = cityName.split(", ");
  const airport = airportData.find(
    (item) =>
      item.city.toLowerCase() === city.toLowerCase() &&
      item.country.toLowerCase() === country.toLowerCase()
  );

  return airport.airport;
};