import React from "react";
import { Routes, Route } from "react-router-dom";
import useUserDataFetching from "./assets/functions/useUserDataFetching";
import HomeLayout from "./layouts/HomeLayout";
import AboutPage from "./pages/AboutPage";
import BookingPage from "./pages/BookingPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import MyBookingsPage from "./pages/MyBookingsPage";
import ResultsPage from "./pages/ResultsPage";
import SignupPage from "./pages/SignupPage";
import SuccessPage from "./pages/SuccessPage";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";

const App = () => {
  
  useUserDataFetching();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomeLayout>
            <HomePage />
          </HomeLayout>
        }
      />
      <Route
        path="/search-results"
        element={
          <HomeLayout>
            <ResultsPage />
          </HomeLayout>
        }
      />
      <Route
        path="/complete-booking"
        element={
          <HomeLayout>
            <BookingPage />
          </HomeLayout>
        }
      />
      <Route
        path="/create-a-user-account"
        element={
          <HomeLayout>
            <SignupPage />
          </HomeLayout>
        }
      />
      <Route
        path="/my-bookings"
        element={
          <HomeLayout>
            <MyBookingsPage />
          </HomeLayout>
        }
      />
      <Route
        path="/login"
        element={
          <HomeLayout>
            <LoginPage />
          </HomeLayout>
        }
      />
      <Route
        path="/about-us"
        element={
          <HomeLayout>
            <AboutPage />
          </HomeLayout>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <HomeLayout>
            <TermsAndConditionPage />
          </HomeLayout>
        }
      />
      <Route path="/booking-completed" element={<SuccessPage />} />
    </Routes>
  );
};

export default App;
