import React, { useState } from "react";
import api from "../assets/apis/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const LoginPage = () => {
  const navigate = useNavigate();
  const [hidden, sethidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const user = { email, password };
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await api.post("/login", user);
      toast.success(response.data.message);
      const token = response.data.access_token;
      localStorage.setItem("access_token", token);
      const res = await api.post("/me");
      dispatch({ type: "SET_USER", payload: res.data });
      navigate(-1);
    } catch (error) {
      toast.error(error.response.data.error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen bg-homebg bg-center bg-no-repeat bg-cover">
      <div className="w-full h-full flex flex-col gap-5 justify-center items-center backdrop-blur-sm bg-black/30">
        <form
          onSubmit={handleSubmit}
          className="w-5/6 md:w-1/2 lg:w-1/3 p-10 bg-white shadow border border-gray-100 rounded-md gap-2 flex flex-col"
        >
          <h1 className="w-full text-center text-lg text-primary">Login</h1>
          <input
            type="email"
            placeholder="Email"
            className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type={hidden ? "password" : "text"}
            placeholder="Password"
            className="w-full outline-none border-2 border-gray-100 rounded focus-within:border-primary transition-all ease-in-out duration-700 text-xs p-1 text-primary"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="w-full flex justify-end">
            {hidden ? (
              <div
                onClick={() => sethidden(!hidden)}
                className="flex items-center gap-1 text-primary text-xs cursor-pointer"
              >
                <p className="">Show password</p>
                <i class="fa-regular fa-eye"></i>
              </div>
            ) : (
              <div
                onClick={() => sethidden(!hidden)}
                className="flex items-center gap-1 text-primary text-xs cursor-pointer"
              >
                <p className="">Hide password</p>
                <i class="fa-regular fa-eye-slash"></i>
              </div>
            )}
          </div>
          {loading ? (
            <div className="w-full flex justify-center">
              <div className="loader"></div>
            </div>
          ) : (
            <button className="w-full py-1.5 text-xs text-black bg-primary shadow rounded transition-all ease-in-out duration-500 hover:bg-gray-100 hover:text-primary">
              Login
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
